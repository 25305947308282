import colorPalete from 'theme/assets/ColorPalete.json'
import groupBy from 'lodash-es/groupBy'
import keyBy from 'lodash-es/keyBy'

export function getGroupedColors (key, brand = null) {
  let localColorPalete = colorPalete.map(color => {
    color.Marka = color.Marka.split('_')[0].toLowerCase() // pretty brand in json
    return color
  })
  let colors = brand ? localColorPalete.filter(color => color.Marka === brand.toLowerCase()) : null

  let groupByBrand = colors
    ? groupBy(colors, key)
    : null

  if (!groupByBrand) { return groupByBrand }

  for (let brandKey in groupByBrand) {
    groupByBrand[brandKey] = groupBy(groupByBrand[brandKey], 'Paleta w SKU')
    for (let paletaKey in groupByBrand[brandKey]) {
      groupByBrand[brandKey][paletaKey] = groupBy(groupByBrand[brandKey][paletaKey], 'Nazwa')
    }
  }

  return groupByBrand
}

export function getKeyedColors (key, brand = null) {
  let localColorPalete = colorPalete.map(color => {
    color.Marka = color.Marka.split('_')[0].toLowerCase() // pretty brand in json
    return color
  })
  let colors = brand ? localColorPalete.filter(color => color.Marka === brand.toLowerCase()) : null

  let groupByBrand = colors
    ? keyBy(colors, key)
    : null

  return groupByBrand
}
